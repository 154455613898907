import { turnZodPathIntoString } from "@wind/form-schema";

import type { ZodIssue } from "../models/ZodIssue.js";
import { useFormContext } from "./useFormContext.js";

export const useFieldIssues = (
  name: string,
  options: {
    includeChildIssues?: boolean;
  } = {}
) => {
  const formContext = useFormContext();

  // Check if there are any matching issues
  const matchingIssues = formContext?.formIssues?.filter((issue: ZodIssue) => {
    const issuePathArray = issue.path ?? [];
    const pathString = turnZodPathIntoString(issuePathArray);

    if (options.includeChildIssues) {
      return pathString.startsWith(name);
    } else {
      return pathString === name;
    }
  });

  return matchingIssues ?? [];
};

export const useFieldIssue = (
  name: string,
  options: {
    includeChildIssues?: boolean;
  } = {}
) => {
  const formContext = useFormContext();

  // Check if there are any matching issues
  const matchingIssue = formContext?.formIssues?.find((issue: ZodIssue) => {
    const issuePathArray = issue.path ?? [];
    const pathString = turnZodPathIntoString(issuePathArray);

    if (options.includeChildIssues) {
      return pathString.startsWith(name);
    } else {
      return pathString === name;
    }
  });

  return matchingIssue;
};
