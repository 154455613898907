import type { PropsWithChildren } from "react";

import ComponentLabelWrapper, {
  type ComponentLabelWrapperProps,
} from "../../../components/ComponentLabelWrapper/ComponentLabelWrapper.js";
import cn from "../../../util/cn.js";
import { useFieldIssue } from "../../hooks/useFieldIssue.js";

interface Props extends ComponentLabelWrapperProps {
  className?: string;
}

// Omit any types that shouldn't be directly ediable on the input
export type InputWrapperProps = Omit<Props, "children" | "htmlFor">;

const InputWrapper = ({
  className,
  label,
  htmlFor,
  helpText,
  children,
  helpTextPlacement = "bottom",
  inline,
}: PropsWithChildren<Props>) => {
  // Check if there are any matching issues
  const issue = useFieldIssue(htmlFor!);

  return (
    <div className={className} data-testid={`input-${htmlFor}-wrapper`}>
      <div
        className={cn({
          "flex items-center gap-2": inline,
        })}
      >
        <ComponentLabelWrapper
          label={label}
          htmlFor={htmlFor}
          helpText={helpText}
          helpTextPlacement={helpTextPlacement}
          inline={inline}
        >
          {children}
        </ComponentLabelWrapper>
        {issue && <p className="mt-2 text-sm text-red-500">{issue.message}</p>}
      </div>
    </div>
  );
};

export default InputWrapper;
