export var turnZodPathIntoString = function(path) {
    var pathString = "";
    for(var i = 0; i < path.length; i++){
        var part = path[i];
        if (typeof part === "number") {
            pathString += "[".concat(part, "]");
        } else {
            if (i > 0) {
                pathString += ".";
            }
            pathString += part;
        }
    }
    return pathString;
};
