import type { PropsWithChildren, ReactNode } from "react";

import cn from "../../util/cn.js";
import Paragraph from "../Typography/Paragraph.js";

export interface ComponentLabelWrapperProps {
  label?: ReactNode;
  htmlFor: string;
  helpText?: ReactNode;
  helpTextPlacement?: "top" | "bottom";
  inline?: boolean;
}

const ComponentLabelWrapper = ({
  label,
  htmlFor,
  helpText,
  helpTextPlacement = "bottom",
  children,
  inline,
}: PropsWithChildren<ComponentLabelWrapperProps>) => {
  const help = <Paragraph>{helpText}</Paragraph>;

  return (
    <>
      {label && (
        <label
          htmlFor={htmlFor}
          className={cn("block cursor-pointer text-sm font-medium", {
            "mb-1": !inline,
          })}
        >
          {label}
        </label>
      )}
      {helpText && helpTextPlacement === "top" && (
        <label htmlFor={htmlFor} className="mb-1 block">
          {help}
        </label>
      )}
      {children}
      {helpText && helpTextPlacement === "bottom" && (
        <label htmlFor={htmlFor} className="mt-1">
          {help}
        </label>
      )}
    </>
  );
};

export default ComponentLabelWrapper;
